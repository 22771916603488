<template>
  <div class="challengesPage">
    <div>
      <!--      <div class="orgProjectDropdown"><OrganizationSelector /></div>-->

      <div class="title_challenge">
        <h1 :style="isMobile ? 'font-size:2em;' : 'font-size: 3em;'">
          {{ $t("Challenges") }}
        </h1>
        <div>
          <CButton
            data-testid="createNewChallengeButton"
            @click="openChallengeModal"
            color="primary"
            class="create-btn"
          >
            {{ $t("Create a challenge") }}
          </CButton>
        </div>
      </div>

      <div class="challenges_container">
        <el-table
          @selection-change="handleSelectionChange"
          class="desktop--table challenge--table org--table"
          :data="
            challengesData.slice(
              (currentPage - 1) * perPage,
              currentPage * perPage
            )
          "
        >
          <el-table-column type="selection" width="55" />

          <el-table-column
            prop="title"
            :label="$t('Title')"
            data-testid="challenge-title"
          >
            <template #default="scope">
              <div class="title-cell">
                {{ scope.row.title }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('Description')">
            <template #default="scope">
              <div>
                <i
                  class="fa fa-eye blue"
                  v-c-tooltip="{
                    content: scope.row.description,
                    placement: 'top',
                  }"
                ></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="due_date"
            :label="$t('Due date')"
            width="120"
          />

          <el-table-column label="Badge" width="120">
            <template #default="scope">
              <div
                v-html="getBadgeSVG(scope.row.badge, scope.row)"
                class="table_badge"
                :class="{
                  bronze_badge: scope.row.color === 'Bronze',
                  silver_badge: scope.row.color === 'Silver',
                  gold_badge: scope.row.color === 'Gold',
                  teal_badge: scope.row.color === 'Teal',
                  light_green_badge: scope.row.color === 'Light Green',
                }"
              ></div>
            </template>
          </el-table-column>

          <el-table-column
            prop="challenge_type"
            :label="$t('Type')"
            width="120"
          />
          <el-table-column
            prop="individual_team"
            label="Audience"
            width="120"
          />
          <!-- <el-table-column prop="unique" :label="$t('Unique')" width="120" /> -->
          <el-table-column prop="goal" :label="$t('Goal')" width="120">
            <template #default="scope">
              <span>{{
                scope.row.goal ? scope.row.goal : $t("Not specified")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Active" width="120">
            <template #default="scope">
              <RaSwitch
                color="#0071ce"
                size="sm"
                :checked="scope.row.is_active"
                v-c-tooltip="{
                  content: TurnStatus(scope.row.deadline, scope.row.is_active),
                  placement: 'top',
                }"
                @change="setChallengeActive(scope.row)"
              />
            </template>
          </el-table-column>

          <el-table-column :label="$t('Actions')" width="120">
            <template #header>
              <el-popconfirm
                @confirm="confirmDeletion()"
                :title="$t('Are you sure to delete all challenges ?')"
              >
                <template #reference>
                  <el-button
                    data-testid="deleteAllChallengesButton"
                    link
                    size="small"
                    type="danger"
                    :style="
                      challengesData.length &&
                      multipleSelection.length ===
                        challengesData.slice(
                          (currentPage - 1) * perPage,
                          currentPage * perPage
                        ).length
                        ? 'display:unset'
                        : 'display:none'
                    "
                    >{{ $t("Delete all") }}</el-button
                  >
                </template>
              </el-popconfirm>
              <el-popconfirm
                :title="$t('Are you sure to delete selected challenges ?')"
                @confirm="confirmDeletion()"
              >
                <template #reference>
                  <el-button
                    link
                    size="small"
                    type="danger"
                    :style="
                      multipleSelection.length &&
                      multipleSelection.length !=
                        challengesData.slice(
                          (currentPage - 1) * perPage,
                          currentPage * perPage
                        ).length
                        ? 'display:unset'
                        : 'display:none'
                    "
                    >{{ $t("Delete selected") }}</el-button
                  >
                </template>
              </el-popconfirm>
            </template>
            <template #default="scope">
              <div style="white-space: nowrap">
                <el-button
                  data-testid="editChallengeButton"
                  size="small"
                  link=""
                  type="primary"
                  :class="'action_btn ' + scope.row._id"
                  @click="editItem(scope.row)"
                  >{{ $t("Edit") }}</el-button
                >

                <el-popconfirm
                  @confirm="confirmDeletion(scope.row._id)"
                  :title="$t('Are you sure to delete selected challenge ?')"
                >
                  <template #reference>
                    <el-button
                      data-testid="deleteChallengeButton"
                      link
                      size="small"
                      type="danger"
                      :class="'action_btn ' + scope.row._id"
                      >{{ $t("Delete") }}</el-button
                    >
                  </template>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="small--screen--table mt-5">
          <div
            class="table_container d-flex flex-column gap-5 p-3"
            style="border: 1px solid #c3c3c3"
            v-for="(item, index) in challengesData.slice(
              (currentPage - 1) * perPage,
              currentPage * perPage
            )"
            :key="index"
          >
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column w-50 align-items-start">
                <label style="color: #979797">{{ $t("Title") }}</label>
                <span>{{ item.title }}</span>
              </div>
              <div class="d-flex flex-column w-50 align-items-start">
                <label style="color: #979797">{{ $t("Description") }}</label>
                <i
                  class="text-center fa fa-eye blue"
                  v-c-tooltip="{
                    content: item.description,
                    placement: 'top',
                  }"
                ></i>
              </div>
            </div>
            <div class="d-flex mt-2 justify-content-between">
              <div class="d-flex flex-column align-items-start">
                <label style="color: #979797">{{ $t("Audiance") }}</label>
                <span>{{ item.individual_team }}</span>
              </div>
              <div class="d-flex flex-column align-items-start">
                <label style="color: #979797">{{ $t("Type") }}</label>
                <span>{{ item.challenge_type }}</span>
              </div>

              <div class="d-flex flex-column align-items-start">
                <label style="color: #979797">{{ $t("Due date") }}</label>
                <span>{{ item.due_date }}</span>
              </div>
            </div>
            <div class="d-flex gap-3 w-100 justify-content-between">
              <div class="d-flex flex-column w-20 align-items-start">
                <label style="color: #979797">{{ $t("Badge") }}</label>
                <span
                  v-html="getBadgeSVG(item.badge, item)"
                  :class="{
                    bronze_badge: item.color === 'Bronze',
                    silver_badge: item.color === 'Silver',
                    gold_badge: item.color === 'Gold',
                    teal_badge: item.color === 'Teal',
                    light_green_badge: item.color === 'Light Green',
                  }"
                ></span>
              </div>
              <!-- <div class="d-flex flex-column w-20 align-items-start">
                <label style="color: #979797">{{ $t("Unique") }}</label>
                <span>{{ item.unique }}</span>
              </div> -->
              <div class="d-flex flex-column w-20 align-items-start">
                <label style="color: #979797">{{ $t("Goal") }}</label>
                <span>{{ item.goal ? item.goal : $t("Not specified") }}</span>
              </div>

              <div class="d-flex flex-column w-20 align-items-start">
                <label style="color: #979797">{{ $t("Actions") }}</label>
                <div style="margin-left: 5px">
                  <span
                    style="cursor: pointer"
                    v-c-tooltip="{
                      content: $t('Edit Challenge'),
                      placement: 'top',
                    }"
                    @click="openEditModal(item)"
                  >
                    <i class="blue fa fa-pencil"></i>
                  </span>
                  <span
                    style="cursor: pointer; margin-left: 10px"
                    v-c-tooltip="{
                      content: $t('Delete Challenge'),
                      placement: 'top',
                    }"
                    @click="openDeleteModal(item)"
                  >
                    <img
                      class="img"
                      :src="require('@/assets/Images/cross.png')"
                      alt="delete"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination-container">
          <div>
            <p style="font-size: 13px">
              {{ `${displayedItems} / ${challengesData.length} lignes` }}
            </p>
          </div>
          <div class="">
            <MzPagination
              :align="center"
              v-model="currentPage"
              :active-page="currentPage"
              :pages="totalPages"
              @update:activePage="handlePageChange"
            >
              <template v-slot:previous-button>
                <i
                  class="mz-icon icon-arrow-collapse pointer"
                  @click="previousPage"
                ></i>
              </template>

              <template v-slot:next-button>
                <i
                  class="mz-icon icon-arrow-collapse pointer"
                  @click="nextPage"
                ></i>
              </template>
            </MzPagination>
          </div>
          <div class="d-flex">
            <span class="mt-2 mr-2" style="font-size: 13px"
              >{{ $t("Per page") }}:</span
            >
            <CSelect
              :v-model="
                perPage === this.challengesData.length ? 'All' : perPage
              "
              style="
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
                cursor: pointer;
              "
              :options="perOptions"
              @input="changePerPage($event.target.value)"
            />
          </div>
        </div>
      </div>
    </div>
    <AddNewChallenge
      ref="AddChallenges"
      :show="showModalAdd"
      @close="closeChallengeModal"
      :editable="isEdit"
      :selectedChallengeData="selectedChallengeData"
    />

    <DeleteChallenge
      :showConfirmDeletion="showConfirmDeletion"
      @close="closeDeleteModal"
      @confirm="confirmDeletion"
    />
  </div>
</template>

<script>
import { images } from "@/assets/Images/images";
import vSelect from "vue-select";
import AddNewChallenge from "./component/AddNewChallenge.vue";
import { OrganizationsService } from "@/services/organizations";
import { ContentLoader } from "vue-content-loader";
import { ChallengeService } from "@/services/challenges";
import { UsersService } from "@/services/users";
import DeleteChallenge from "./component/DeleteChallenge.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import { showMessage } from "../../utils/helpers";
import { useGlobalStore } from "../../stores/store";
import { useUserStore } from "@/stores/UserStore";
import { mapWritableState } from "pinia";
import { ElLoading } from "element-plus";

const badges = images.challenges;
export default {
  name: "Challenges",
  components: {
    AddNewChallenge,
    vSelect,
    ContentLoader,
    DeleteChallenge,
    OrganizationSelector,
  },
  data: () => {
    return {
      userStore: useUserStore(),
      globalStore: useGlobalStore(),
      challengeId: "",
      showConfirmDeletion: false,
      loading: true,
      selectedOrganization: null,
      showOptions: false,
      multipleSelection: [],
      orgOptions: [],
      challengesData: [],
      tableFields: [
        { key: "title", label: "Title" },
        { key: "description", label: "Description" },
        { key: "due_date", label: "Due Date" },
        { key: "badge", label: "Badge" },
        { key: "challenge_type", label: "Type" },
        { key: "individual_team", label: "Audience" },
        // { key: "unique", label: "Unique" },
        { key: "goal", label: "Goal" },
        { key: "actions", label: "Actions" },
        { key: "active", label: "Active" },
      ],
      tableFieldsFr: [
        { key: "title", label: "Titre" },
        { key: "description", label: "Description" },
        { key: "due_date", label: "Date limite" },
        { key: "badge", label: "Badge" },
        { key: "challenge_type", label: "Type" },
        { key: "individual_team", label: "Audience" },
        // { key: "unique", label: "Unique" },
        { key: "goal", label: "Goal" },
        { key: "actions", label: "Actions" },
        { key: "active", label: "Active" },
      ],
      currentPage: 1,
      perPage: 25,
      perOptions: [25, 50, 100, 200, "All"],
      displayedItems: 0,
      selectedRole: null,
      showModalAdd: false,
      isEdit: false,
      selectedChallengeData: null,
      projectRole: [
        { value: "Tout", text: "tout" },
        { value: "Sponsor", text: "sponsor" },
        {
          value: "Manager",
          text: "manager",
        },
      ],
    };
  },
  async created() {
    this.refreshEverything();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.challengesData.length / this.perPage);
    },
    ...mapWritableState(useGlobalStore, {
      isMobile: "isMobile",
    }),
  },
  watch: {
    currentPage() {
      this.updateDisplayedItems();
    },
    perPage() {
      this.updateDisplayedItems();
    },
  },
  methods: {
    async setChallengeActive(challenge) {
      const challengeActiveState = !challenge.is_active;
      const { data, res } = await ChallengeService.set_challenge_active({
        _id: challenge._id,
        active: challengeActiveState,
      });
      if (res.status === 200) {
        if (challengeActiveState) {
          showMessage("success", "Challenge is Active!");
        } else {
          showMessage("success", "Challenge is Deactivated!");
        }
        challenge.is_active = challengeActiveState;
      } else {
        if (challengeActiveState) {
          showMessage("error", "Error setting commincation Active!");
        } else {
          showMessage("error", "Error desactivating News!");
        }
      }
    },
    TurnStatus(deadline, status) {
      if (new Date(deadline) >= new Date()) {
        if (status) {
          return "Turn Off";
        } else {
          return "Turn On";
        }
      } else {
        return "Turn On";
      }
    },
    async refreshEverything() {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      await this.getChallengesData();
      await this.getListOrganization();
      this.displayedItems = Math.min(this.perPage, this.challengesData.length);
      loading.close();
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage;
      }
    },
    updateDisplayedItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = Math.min(start + this.perPage, this.challengesData.length);
      this.displayedItems = end - start;
    },
    openDeleteModal(id) {
      this.challengeId = id;
      this.showConfirmDeletion = true;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    closeDeleteModal() {
      this.showConfirmDeletion = false;
    },
    async confirmDeletion(rowId) {
      let ids = [];
      if (this.multipleSelection.length) {
        ids = this.multipleSelection.map((challenge) => challenge._id);
      } else {
        ids = [rowId];
      }
      const { data, res } = await ChallengeService.removeChallenge(ids);
      if (res.status === 200) {
        showMessage("success", "Challenge deleted successfully !");
        const userData = await UsersService.getActiveUserInfo();
        if (userData.data) {
          this.$store.commit("setUserData", userData.data);
          this.$store.commit("setOrgObj", userData.data.current_organization);
          this.$store.commit("setJobsPoints", userData.data.level_points_v2);
          const firstEntry = Object.entries(
            userData.data.current_job_description
          )[0];
          this.$store.commit("setJob", firstEntry);

          let currentLevel = userData.data.current_level;
          if (currentLevel === "") {
            let currentRole = Object.keys(
              userData.data.current_job_description
            )[0];
            currentLevel = userData.data.level_points_v2.map((level) => {
              if (level.job === currentRole) {
                return level.level;
              }
            })[0];
          }
          this.userStore.setCurrentLevel(currentLevel);
          this.userStore.setUserPoints(userData.data.total_points);
          this.userStore.setUserTrainingPoints(
            userData.data.training_total_points
          );
          this.userStore.setUserTrainingProgress(userData.data.training_points);
          const teamPointsData = userData.data.level_points_v2;
          const neededPointsData = teamPointsData.filter((point) => {
            return point.job === firstEntry[0] && point.level === currentLevel;
          })[0];

          this.userStore.setTeamPoints(neededPointsData);
          this.userStore.setUsersProgress(userData.data.total_progress);
          const teamProgressData = userData.data.level_progress_v2;
          const neededProgressData = teamProgressData.filter((point) => {
            return (
              point.job === firstEntry[0] &&
              point.level === userData.data.current_level
            );
          })[0];
          this.userStore.setAllRolePoints(userData.data.all_roles_points);
          this.userStore.setUsersTeamProgress(neededProgressData);
          this.globalStore.setUpdatedProgress(false);
        }
      } else {
        showMessage("error", "Challenge deleted failed !");
      }

      this.refreshEverything();
    },
    getBadgeSVG(badgeId, id) {
      if (Object.keys(id).length) {
        const badge = badges[badgeId];
        if (badge) {
          return badge;
        } else {
          console.error("Badge not found " + id);
          return;
        }
      }
    },
    async getChallengesData() {
      try {
        const { data, res } = await ChallengeService.getChallenges();
        this.challengesData = data.data;
        this.updateDisplayedItems();
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
    async getListOrganization() {
      try {
        const response =
          await OrganizationsService.list_organizations_and_projects();
        const orgsWithProjects = response.data.data;
        const orgOptions = [];
        orgsWithProjects.forEach((org) => {
          if (org.projects && org.projects.length > 0) {
            org.projects.forEach((project) => {
              orgOptions.push({
                code: `${org.organization} / ${project.name}`,
                value: `${org.organization} / ${project.name}`,
              });
            });
          } else {
            orgOptions.push({
              code: `${org.organization} / No Projects`,
              value: `${org.organization} / No Projects`,
            });
          }
        });
        this.orgOptions = orgOptions;
        this.selectedOrganization = this.orgOptions[0].value;
      } catch (error) {
        console.error("error fetching data", error);
      } finally {
      }
    },
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    editItem(item) {
      this.isEdit = true;
      this.showModalAdd = true;
      this.selectedChallengeData = item;
    },
    openChallengeModal() {
      this.isEdit = false;
      this.selectedChallengeData = null;
      this.showModalAdd = true;
      this.$refs.AddChallenges.resetData();
    },
    closeChallengeModal() {
      this.showModalAdd = false;
    },

    changePerPage(value) {
      if (value === "All") {
        this.perPage = this.challengesData.length;
        this.currentPage = 1;
      } else {
        this.perPage = value;
        this.currentPage = 1;
      }
      this.displayedItems = Math.min(this.challengesData.length, this.perPage);
      this.totalPages = Math.ceil(this.challengesData.length / this.perPage);
    },
  },
};
</script>
<style>
.challenge--table.el-table {
  height: unset;
  display: block;
}

.challenge--table.el-table td {
  background: none;
  background-color: none;
}
.challenge--table .el-table__body-wrapper {
  max-height: 60dvh !important;
  overflow: auto;
}
.el-table__inner-wrapper {
  height: 100%important;
}
@media screen and (max-width: 1280px) {
  .challenge--table .el-table__body-wrapper {
    max-height: 55dvh !important;
  }
}
</style>
<style scoped>
.title-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.title_challenge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.challenges_container {
  max-height: calc(100vh - 226px);
  display: flex;
  flex-direction: column;
}
.blue {
  color: #0071ce !important;
}
.img {
  margin-top: -2px;
  width: 12px;
  height: 12px;
}
.small--screen--table {
  display: none;
  overflow: auto;
  background: white;
}
.action_btn {
  display: none;
}
tr:hover .action_btn {
  display: unset;
}
.dropdown {
  border-bottom: 1px solid var(--neutral-medium-light, #9d9d9d);
  width: 260px;
}
.dropdown-menu {
  min-width: 16.2rem;
}
.svg-overlap {
  width: 50px;
  height: 50px;
  align-items: center;
  display: flex;
}
.create-btn {
  border-radius: 0px 0px 0px 25px;
  height: 40px;
}
.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  background-color: #fff;
  height: 58px;
  width: 100%;
  padding: 40px 20px 25px 20px;
  border: 1px solid #c3c3c3;
  z-index: 1 !important;
}

.edit-button {
  background: none;
  color: var(--link-color, #007bff);
  border: none;
}
tr:hover {
  background-color: #e7f0fa;
}

.cus-table {
  margin-left: 8px;
  margin-right: 15px;
}

.cus-table ::v-deep thead th {
  background-color: #f4f4f4 !important;
}

.perpage {
  width: 60px;
  margin-top: 10px;
}

@media (max-width: 970px) {
  .desktop--table {
    display: none;
  }
  .small--screen--table {
    display: block;
  }
  .org--table .el-table__body-wrapper {
    max-height: calc(100dvh - 380px);
  }
}
@media (max-width: 770px) {
  .desktop--table {
    display: none;
  }
  .small--screen--table {
    display: block;
  }
  .org--table .el-table__body-wrapper {
    max-height: calc(100dvh - 380px);
  }
}
@media (max-width: 970px) {
  .challengesPage {
    margin-top: 50px !important;
    max-height: calc(100vh - 152px);
    overflow: auto;
  }
}

@media (max-width: 423px) {
  .title_challenge {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
<style scoped>
::v-deep .el-scrollbar__wrap {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
::v-deep .table_badge.bronze_badge circle {
  fill: #e2a963;
}
::v-deep .table_badge.bronze_badge path:first-child {
  fill: #de9336;
}

::v-deep .table_badge.silver_badge circle {
  fill: #d3d3d3;
}
::v-deep .table_badge.silver_badge path:first-child {
  fill: #b2aeae;
}
::v-deep .table_badge.teal_badge circle {
  fill: #a2c1bf;
}
::v-deep .table_badge.teal_badge path:first-child {
  fill: #53908b;
}
::v-deep .table_badge.light_green_badge circle {
  fill: #dfffe0;
}
::v-deep .table_badge.light_green_badge path:first-child {
  fill: #7feb45;
}
</style>
