<template>
  <div>
    <CModal
      :show="show"
      :top="true"
      :draggable="false"
      alignment="top"
      class="no-overflow"
    >
      <template v-slot:header>
        <h3 style="padding-left: 1rem">
          {{ editable ? $t("Edit Challenge") : $t("Add Challenge") }}
        </h3>
        <CButton @click="closeChallengeModal">
          <i class="fa fa-times"></i>
        </CButton>
      </template>

      <div class="challengesmodal">
        <ScopeSelections
          :show="show"
          :preSelectedUsers="selectedChallengeUsers"
          :isSwitchOn="isSwitchOn ? 'yes' : 'no'"
          @usersChanged="selectedUsersChanged"
          @levelsChanged="selectedLevelsChanged"
          @rolesChanged="selectedRolesChanged"
          @selectAll="selectedAllUsers"
        />

        <div>
          <label for="title">{{ $t("Title") }}*</label>
          <!-- <CInput class="mb-2" v-model="title" data-testid="newChallengeTitle" /> -->
          <el-input
            data-testid="newChallengeTitle"
            v-model="title"
            :placeholder="$t('Title')"
          />
        </div>
        <div style="margin-top: 10px">
          <label for="description">{{ $t("Description") }}*</label>
          <!-- <CInput
          class="mb-2"
          v-model="description"
          data-testid="newChallengeDescription"
          /> -->
          <el-input
            data-testid="newChallengeDescription"
            v-model="description"
            :placeholder="$t('Description')"
          />
        </div>
        <div style="margin-top: 10px">
          <div>
            <label>{{ $t("Due date") }}*</label>
          </div>

          <div>
            <el-date-picker
              data-testid="newChallengeDueDate"
              v-model="dueDate"
              type="date"
              placeholder="Challenge Date"
              :size="size"
              format="DD/MM/YYYY"
              style="width: 100%"
            />
          </div>
        </div>

        <div style="margin-top: 10px">
          <div>
            <label>{{ $t("Badge") }}*</label>
            <el-select
              v-model="selectedColor"
              placeholder="Color theme"
              style="margin-bottom: 10px"
            >
              <el-option
                v-for="item in colorsList"
                :key="item.label"
                :label="item.label"
                :value="item.label"
              />
            </el-select>
            <div class="badgesSection">
              <div v-for="item in badges">
                <div
                  v-html="getBadgeSvg(item.id)"
                  class="badges"
                  :class="{
                    selected_badge: selectedBadge === item.id,
                    not_selected_badge: selectedBadge !== item.id,
                    bronze_badge: selectedColor === 'Bronze',
                    silver_badge: selectedColor === 'Silver',
                    gold_badge: selectedColor === 'Gold',
                    teal_badge: selectedColor === 'Teal',
                    light_green_badge: selectedColor === 'Light Green',
                    purple_badge: selectedColor === 'Purple',
                  }"
                  @click="selectedBadge = item.id"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 10px">
          <label>{{ $t("Type") }}*</label>
          <el-select
            data-testid="newChallengeType"
            v-model="selectedType"
            :options="types"
            label="label"
            track-by="id"
            :placeholder="$t('Select type')"
            :disabled="editable"
          >
            <el-option
              data-testid="newChallengeTypeOption"
              v-for="item in types.map((el) => ({
                value: el,
                label: el,
                id: el,
              }))"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div style="margin-top: 10px">
          <label>{{ $t("Audience") }}*</label>
          <div class="d-flex">
            <div class="form-check">
              <input
                data-testid="newChallengeAudienceIndividual"
                class="form-check-input pointer"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="individual"
                v-model="selectedAudience"
                :disabled="editable"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                {{ $t("Individual") }}
              </label>
            </div>
            <div class="form-check ml-3">
              <input
                data-testid="newChallengeAudienceTeam"
                class="form-check-input pointer"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="team"
                v-model="selectedAudience"
                :disabled="editable"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                {{ $t("Team") }}
              </label>
            </div>
            <div class="form-check d-flex justify-content-end w-100">
              <RaSwitch
                color="#0071ce"
                size="sm"
                :checked="
                  new Date(dueDate) <= new Date().setHours(0, 0, 0, 0)
                    ? false
                    : isSwitchOn
                "
                class="ml-2"
                style="margin-top: 2px"
                v-c-tooltip="{
                  content: TurnStatus(dueDate, isSwitchOn),
                  placement: 'top',
                }"
                @change="isSwitchOn = !isSwitchOn"
                :disabled="new Date(dueDate) <= new Date().setHours(0, 0, 0, 0)"
              />
              <label class="ml-2" style="margin-top: 2px">{{
                $t("Active Challenge")
              }}</label>
            </div>
          </div>
        </div>

        <div class="mt-2" v-if="isGoalVisible">
          <CInput
            data-testid="newChallengeGoalInPoint"
            :disabled="editable"
            label="Goal in points*"
            type="number"
            v-model="goalInPoint"
          />
        </div>
        <div class="w-100 mt-2" v-if="editable && selectedType === 'custom'">
          <div v-if="selectedAudience === 'individual'">
            <label>{{ $t("Users") }} *</label>
            <el-tree-select
              style="width: 100%"
              v-model="selectedUsers"
              :data="globalStore.scopeUsersOptions"
              multiple
              :render-after-expand="false"
              show-checkbox
              check-strictly
              check-on-click-node
            />
          </div>
          <div v-else-if="selectedAudience === 'team'">
            <label>{{ $t("Levels") }} *</label>
            <el-tree-select
              style="width: 100%"
              v-model="selectedLevels"
              :data="globalStore.scopeLevelsOptions"
              multiple
              :render-after-expand="false"
              show-checkbox
              check-strictly
              check-on-click-node
            />
          </div>
        </div>
      </div>

      <template v-slot:footer>
        <div class="d-flex justify-content-between w-100 align-items-center">
          <div style="left: 0; font-size: 12px">
            <i>* {{ $t("Mandatory fields") }}</i>
          </div>
          <div>
            <CButton @click="closeChallengeModal" class="link">{{
              $t("Cancel")
            }}</CButton>
            <CButton
              data-testid="newChallengeSave"
              color="primary"
              class="modal-confirm"
              @click="postChallenge"
              :disabled="isSaveDisabled"
            >
              {{ editable ? $t("Edit Challenge") : $t("Add Challenge") }}
            </CButton>
          </div>
        </div>
      </template>
    </CModal>
  </div>
</template>
<script>
import vSelect from "vue-select";
import Treeselect from "vue3-treeselect";
import { images } from "../../../assets/Images/images";
import { OrganizationsService } from "@/services/organizations";
import { ChallengeService } from "@/services/challenges";
import { UsersService } from "@/services/users";
import { useGlobalStore } from "@/stores/store";
import { useUserStore } from "@/stores/UserStore";
import { showMessage, updateMyData } from "../../../utils/helpers";
import ScopeSelections from "@/components/ScopeSelections";
import { ElLoading } from "element-plus";

import { mapState } from "vuex";
export default {
  name: "AddChallenges",
  components: { vSelect, Treeselect, ScopeSelections },
  props: {
    show: Boolean,
    editable: Boolean,
    selectedChallengeData: Array,
  },
  data() {
    return {
      userStore: useUserStore(),
      globalStore: useGlobalStore(),
      selectedUsers: [],
      usersOptions: [],
      selectedFilter: "users",
      selectedColor: "Gold",
      colorsList: [
        { label: "Gold", value: "Gold" },
        { label: "Silver", value: "Silver" },
        { label: "Bronze", value: "Bronze" },
        { label: "Teal", value: "Teal" },
        { label: "Light Green", value: "Light Green" },
        { label: "Purple", value: "Purple" },
      ],
      isGoalVisible: false,
      badges: [
        { label: "Star", id: "star" },
        { label: "Gold Badge", id: "gold_badge" },
        { label: "Gold Medal", id: "gold_medal" },
        { label: "Blue Medal", id: "blue_medal" },
      ],
      selectedBadge: null,
      selectedBadgeSvg: null,
      levels: [],
      selectedLevels: null,
      users: [],
      selectedOrganization: "",
      orgList: [],
      title: "",
      description: "",
      dueDate: "",
      isSwitchOn: true,

      selectedPoints: 0,
      selectedAudience: null,
      goalInPoint: 0,
      types: ["custom", "actions", "training"],
      selectedType: "",
      unique: false,
      scopeSelectedUsers: [],
      scopeSelectedLevels: [],
      selectedRoles: [],
      selectedChallengeUsers: [],
      selectedAll: false,
    };
  },
  computed: {
    isSaveDisabled() {
      this.scopeSelectedUsers = this.scopeSelectedUsers || [];
      this.scopeSelectedLevels = this.scopeSelectedLevels || [];
      this.selectedRoles = this.selectedRoles || [];
      let pointsFilled;
      if (this.selectedType === "actions" || this.selectedType === "training") {
        const goalInPointStr = String(this.goalInPoint).trim();
        pointsFilled = goalInPointStr.length > 0;
      } else {
        pointsFilled = true;
      }
      const commonConditionsMet =
        this.title.trim() &&
        this.description.trim() &&
        this.dueDate &&
        this.selectedBadge &&
        this.selectedType &&
        this.selectedAudience &&
        pointsFilled;

      const isScopeEmpty =
        this.scopeSelectedUsers.length === 0 &&
        this.scopeSelectedLevels.length === 0 &&
        this.selectedRoles.length === 0;

      return !commonConditionsMet || (isScopeEmpty && !this.selectedAll);
    },

    images() {
      return images;
    },
    ...mapState({
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
    }),
  },
  watch: {
    selectedLevels(newVal) {
      if (newVal) {
        const combinedLevels = [...this.scopeSelectedLevels, ...newVal];
        this.scopeSelectedLevels = [...new Set(combinedLevels)];
      }
    },
    selectedUsers(newVal) {
      if (newVal) {
        const combinedUsers = [...this.scopeSelectedUsers, ...newVal];
        this.scopeSelectedUsers = [...new Set(combinedUsers)];
      }
    },

    selectedType(type) {
      this.isGoalVisible =
        type !== "custom" && ["actions", "training"].includes(type);

      if (type === "custom") {
        this.goalInPoint = "";
      }
    },
    selectedBadge: {
      handler(newVal) {
        if (newVal) {
          this.selectedBadgeSvg = this.images.challenges[newVal];
        }
      },
      immediate: true,
    },
    show: {
      handler(newVal) {
        if (newVal) {
          if (this.editable) {
            this.selectedBadge = this.badges.find(
              (badge) => badge.id === this.selectedChallengeData.badge
            ).id;
            this.selectedBadgeSvg = this.selectedChallengeData?.svg;
            this.selectedUsers =
              this.selectedChallengeData.users.length === 0
                ? []
                : this.selectedChallengeData.users;
            this.selectedLevels =
              this.selectedChallengeData.levels.length === 0
                ? []
                : this.selectedChallengeData.levels;
            this.selectedOrganization = this.selectedChallengeData.organization;
            this.dueDate = this.selectedChallengeData.due_date;
            this.selectedColor = this.selectedChallengeData.color
              ? this.selectedChallengeData.color
              : "Gold";
            this.selectedType = this.selectedChallengeData.challenge_type;
            this.goalInPoint = this.selectedChallengeData.type;
            this.title = this.selectedChallengeData.title;
            this.description = this.selectedChallengeData.description;
            this.selectedPoints = this.selectedChallengeData.points;
            this.goalInPoint = this.selectedChallengeData.goal;
            this.selectedAudience = this.selectedChallengeData.individual_team;
            this.unique = this.selectedChallengeData.unique;
            this.isSwitchOn = this.selectedChallengeData.is_active;
            this.selectedChallengeUsers =
              this.selectedChallengeData.users_access;
          } else {
            this.resetData();
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getOrgMembers();
  },
  methods: {
    getBadgeSvg(badgeId) {
      let img = this.images.challenges[badgeId];
      if (!img) return;
      // Retrieve SVG for the given badge ID
      return img;
    },
    TurnStatus(deadline, status) {
      if (new Date(this.dueDate) >= new Date()) {
        if (this.isSwitchOn) {
          return "Turn Off";
        } else {
          return "Turn On";
        }
      } else {
        return "Turn On";
      }
    },
    selectedUsersChanged(selectedUsers) {
      this.scopeSelectedUsers = selectedUsers;
    },
    selectedLevelsChanged(selectedLevels) {
      this.scopeSelectedLevels = selectedLevels;
    },
    selectedRolesChanged(selectedRoles) {
      this.selectedRoles = selectedRoles;
    },
    selectedAllUsers(selectAll) {
      this.selectedAll = selectAll;
    },
    closeChallengeModal() {
      this.resetData();
      this.$emit("close");
    },
    async getOrgMembers() {
      try {
        const { data } = await OrganizationsService.list_org_members({
          organization_name:
            this.currentOrganization.name ||
            this.currentOrganization.organization,
          project_name: this.currentProject.name,
        });

        const usersOptions = [];
        const response = data.data;

        if (this.usersOptions.length > 0) {
          this.usersOptions = [];
        }

        response.forEach((user) => {
          user.org_roles.forEach((role) => {
            const userInfo = `${user.email} - ${role.level_name} - ${role.job_description}`;
            usersOptions.push({
              value: userInfo,
              label: userInfo,
            });
          });
        });

        this.globalStore.setScopeUsersOptions(usersOptions);
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    async postChallenge() {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      const formattedDueDate = new Date(this.dueDate)
        .toISOString()
        .split("T")[0];
      const usersToSend = this.selectedAll
        ? this.globalStore.scopeUsersOptions.map((user) => {
            const [email, level_name, job_description] =
              user.value.split(" - ");
            return {
              email: email.trim(),
              level_name: level_name.trim(),
              job_description: job_description.trim(),
            };
          })
        : this.scopeSelectedUsers.map((user) => {
            const [email, level_name, job_description] = user.split(" - ");
            return {
              email: email.trim(),
              level_name: level_name.trim(),
              job_description: job_description.trim(),
            };
          });

      const payload = {
        title: this.title,
        description: this.description,
        due_date: formattedDueDate,
        badge: this.selectedBadge,
        points: this.selectedPoints,
        individual_team: this.selectedAudience,
        challenge_type: this.selectedType,
        unique: this.unique,
        is_active: this.isSwitchOn,
        goal: this.goalInPoint || "",
        users: this.selectedUsers ? this.selectedUsers : [],
        levels: this.selectedLevels ? this.selectedLevels : [],
        color: this.selectedColor,
      };
      if (!this.editable) {
        // Add challenge
        try {
          const { data, res } = await ChallengeService.createChallenge({
            challengeData: payload,
            users: usersToSend,
            levels: this.selectedAll ? [] : this.scopeSelectedLevels,
            roles: this.selectedAll ? [] : this.selectedRoles,
          });
          if (data.success) {
            showMessage("success", "Challenge created successfully!");
            updateMyData();
          } else {
            showMessage("error", "Error creating challenge");
          }
        } catch (error) {
          console.error("Error creating challenge:", error);
        }
        this.closeChallengeModal();
        this.$parent.refreshEverything();
      } else {
        try {
          const { data, res } = await ChallengeService.updateChallenge({
            id: this.selectedChallengeData._id,
            challengeData: payload,
            users: this.scopeSelectedUsers.map((user) => {
              const [email, level_name, job_description] = user.split(" - ");
              return {
                email,
                level_name,
                job_description,
              };
            }),
            levels: this.scopeSelectedLevels,
            roles: this.selectedRoles,
          });
          if (res.status === 200) {
            showMessage("success", "Challenge updated successfully !");
            const userData = await UsersService.getActiveUserInfo();
            if (userData.data) {
              this.$store.commit("setUserData", userData.data);
              this.$store.commit(
                "setOrgObj",
                userData.data.current_organization
              );
              this.$store.commit(
                "setJobsPoints",
                userData.data.level_points_v2
              );
              const firstEntry = Object.entries(
                userData.data.current_job_description
              )[0];
              this.$store.commit("setJob", firstEntry);

              let currentLevel = userData.data.current_level;
              if (currentLevel === "") {
                let currentRole = Object.keys(
                  userData.data.current_job_description
                )[0];
                currentLevel = userData.data.level_points_v2.map((level) => {
                  if (level.job === currentRole) {
                    return level.level;
                  }
                })[0];
              }
              this.userStore.setCurrentLevel(currentLevel);
              this.userStore.setUserPoints(userData.data.total_points);
              this.userStore.setUserTrainingPoints(
                userData.data.training_total_points
              );
              this.userStore.setUserTrainingProgress(
                userData.data.training_points
              );
              const teamPointsData = userData.data.level_points_v2;
              const neededPointsData = teamPointsData.filter((point) => {
                return (
                  point.job === firstEntry[0] && point.level === currentLevel
                );
              })[0];

              this.userStore.setTeamPoints(neededPointsData);
              this.userStore.setUsersProgress(userData.data.total_progress);
              const teamProgressData = userData.data.level_progress_v2;
              const neededProgressData = teamProgressData.filter((point) => {
                return (
                  point.job === firstEntry[0] &&
                  point.level === userData.data.current_level
                );
              })[0];
              this.userStore.setAllRolePoints(userData.data.all_roles_points);
              this.userStore.setUsersTeamProgress(neededProgressData);
              this.globalStore.setUpdatedProgress(false);
            }
          } else {
            showMessage("error", "Error updating challenge");
          }
        } catch (error) {
          showMessage("error", "Challenge updated successfully !");
          console.error("Error updating challenge", error);
        }
        this.closeChallengeModal();
        this.$parent.refreshEverything();
      }
    },
    resetData() {
      this.selectedUsers = [];
      this.scopeSelectedUsers = [];
      this.selectedChallengeUsers = [];
      this.selectedBadge = null;
      this.selectedBadgeSvg = null;
      this.selectedUser = null;
      this.selectedLevels = null;
      this.selectedOrganization = "";
      this.title = "";
      this.description = "";
      this.dueDate = "";
      this.selectedPoints = 0;
      this.goalInPoint = "";
      this.selectedType = null;
      this.unique = false;
      this.selectedAudience = null;
      this.isSwitchOn = false;
    },
  },
};
</script>
<style scoped>
.badgesSection {
  display: flex;
  justify-content: space-between;
}
.badges {
  cursor: pointer;
}
.unique_margin {
  margin-top: 2px;
}
.no-overflow ::v-deep .challengesmodal {
  overflow: unset !important;
}
.filter_btn {
  cursor: pointer;
  font-size: 14px;
}
.filter_btn:hover {
  color: #0071ce;
}
.filter_btn.selected {
  color: #0071ce;
  font-weight: bold;
  letter-spacing: -0.02em; /* Adjust based on your font and preference */
  border-bottom: 2px solid #0071ce;
}
.challengesmodal {
  max-height: 520px !important;
  overflow: auto !important;
  padding: 0 20px;
}
.modal-body {
  padding: 0 1rem !important;
}
::v-deep .modal-header {
  padding: 1rem 1rem 0 1rem !important;
}
.modal-confirm {
  border-radius: 0px 0px 0px 25px;
  padding: 10px;
  width: 125px;
  margin-left: 10px;
}

::v-deep .el-select__wrapper {
  border-radius: 0;
}

::v-deep .el-input__wrapper {
  border-radius: 0;
}
::v-deep .el-textarea__inner {
  border-radius: 0;
}
::v-deep .modal-footer {
  padding: 0.75rem 32px !important;
}
::v-deep .modal-dialog {
  top: unset !important;
  left: unset !important;
}
::v-deep .not_selected_badge path {
  filter: grayscale(95%);
}
::v-deep .not_selected_badge circle {
  filter: grayscale(95%);
}

::v-deep .badges.selected_badge.bronze_badge circle {
  fill: #e2a963;
}
::v-deep .badges.selected_badge.bronze_badge path:first-child {
  fill: #de9336;
}

::v-deep .badges.selected_badge.silver_badge circle {
  fill: #d3d3d3;
}
::v-deep .badges.selected_badge.silver_badge path:first-child {
  fill: #b2aeae;
}
::v-deep .badges.selected_badge.teal_badge circle {
  fill: #a2c1bf;
}
::v-deep .badges.selected_badge.teal_badge path:first-child {
  fill: #53908b;
}
::v-deep .badges.selected_badge.light_green_badge circle {
  fill: #dfffe0;
}
::v-deep .badges.selected_badge.light_green_badge path:first-child {
  fill: #7feb45;
}
::v-deep .badges.selected_badge.purple_badge circle {
  fill: #fef1ff;
}
::v-deep .badges.selected_badge.purple_badge path:first-child {
  fill: #c149f2;
}
</style>
